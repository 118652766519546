<template>
  <div class="inbox-notif">
    <div class="text-center">
      <!--{{ config }}<br>
      {{ inbox }}
      <button @click="show = !show">Permuter l'affichage</button>-->
      <transition name="bounce">
        <b-badge v-if="show" @click="open_inbox" class="notification">{{
          inbox_cnt
        }}</b-badge>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "InboxNotif",
  data: function() {
    return {
      inbox_cnt: 0,
      show: false,
    };
  },
  created() {
    //  this.webId = this.$route.params.webId || this.$store.state.solid.webId
    //  this.updateFriends()
  },
  watch: {
    config(config) {
      //  '$route' (to, from) {
      console.log(config);
    },
    inbox(inbox) {
      console.log(inbox.files.length);
      if (this.inbox_cnt != inbox.files.length) {
        this.inbox_cnt = inbox.files.length;
        this.show = true;
      }
    },
  },
  methods: {
    open_inbox() {
      if (this.$route.path !== "/inbox") {
        this.$router.push({ path: "/inbox" });
      }
      this.show = false;
    },
  },
  computed: {
    /*  storage(){
  return this.$store.state.solid.storage
},
,
webId: {
get: function() { return this.$store.state.inbox.webId},
set: function() {}
},
inbox_log_file: {
get: function() { return this.$store.state.inbox.inbox_log_file},
set: function() {}
},
inbox_urls: {

},*/
    inbox: {
      get: function() {
        return this.$store.state.inbox.inbox;
      },
      set: function() {},
    },
    config: {
      get: function() {
        return this.$store.state.inbox.config;
      },
      set: function() {},
    },
  },
};
</script>

<style>
.inbox-notif {
  display: inline-block;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.notification {
  background: #fb5c5c;
  border-radius: 10px;
  width: 30px;
  height: 20px;
  text-align: center;
  font-size: 11px;
}
.badge {
  padding: 0;
  vertical-align: middle;
  line-height: 20px;
}
</style>
